import * as React from "react";
import { useMemo } from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Articles } from "../components/articles";
import { blogPosts } from "../utils/data";
import { Badge, Col, Container, Row } from "react-bootstrap";
import Bio from "../components/bio";
import moment from "moment/moment";
import { StaticImage } from "gatsby-plugin-image";

const BlogPage: React.FC<PageProps> = ({ data }) => {
    // @ts-ignore
    const smartPlugs = useMemo(
        () => blogPosts.convert(data.allMarkdownRemark, "product", "smart plug"),
        [data],
    );
    // @ts-ignore
    const robotVacuums = useMemo(
        () => blogPosts.convert(data.allMarkdownRemark, "product", "robot vacuum"),
        [data],
    );

    return (
        <Layout activeKey={"/smart-home-products"}>
            <div itemScope itemType="https://schema.org/Article">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className={"mainheading"}>
                                <h1 itemProp="headline">
                                    Explore Our Comprehensive Smart Home Products Database
                                </h1>
                                <StaticImage
                                    src={"../images/jakub-zerdzicki-Wut0F41K9ZU-unsplash.jpg"}
                                    alt={"smart home products"}
                                    loading={"eager"}
                                    itemProp={"image"}
                                />
                                <meta itemProp={"datePublished"} content={moment().format()} />
                                <p className="lead">
                                    Welcome to the <strong>Real Smart Home</strong> products page,
                                    where you will find an extensive and comprehensive database of{" "}
                                    <strong>smart home devices</strong> designed to make your life
                                    more convenient, secure, and energy-efficient. From
                                    <strong>robot vacuums</strong> that clean on autopilot to{" "}
                                    <strong>smart plugs</strong> that bring automation to your
                                    everyday electronics, our collection ensures you have the best
                                    products to transform your living space into a connected,
                                    automated, and intelligent environment.
                                </p>
                                <p className="lead">
                                    Whether you're looking to enhance security with integrated
                                    <strong>smart cameras</strong> or reduce energy costs with{" "}
                                    <strong>smart thermostats</strong>, our expertly curated product
                                    listings cover all the essentials. As smart home technology
                                    continues to evolve, staying ahead of the trends and integrating
                                    the latest devices into your daily routine has never been
                                    easier.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className={"mainheading"}>
                                <h2>Robot Vacuums for Effortless Cleaning</h2>
                                <h3>Automating Your Cleaning Routine</h3>
                                <p className="lead">
                                    Keeping your home clean has never been easier thanks to the
                                    advent of the robot vacuum. These intelligent devices take the
                                    hassle out of daily cleaning, offering advanced navigation,
                                    powerful suction, and scheduled cleaning options. Whether you
                                    have hardwood floors, carpets, or mixed surfaces, robot vacuums
                                    adapt to different environments and work autonomously to keep
                                    your home spotless.
                                </p>
                                <p className="lead">
                                    What sets modern robot vacuums apart is their ability to map
                                    your home and remember the layout, which results in more
                                    efficient cleaning cycles. Additionally, many models are
                                    equipped with features like automatic dirt disposal and
                                    multi-floor mapping, making them a must-have in any modern smart
                                    home.
                                </p>

                                <h3>Seamless Integration with Other Smart Devices</h3>
                                <p className="lead">
                                    A robot vacuum can be controlled through your smartphone and
                                    integrated with other smart home devices, such as smart lights
                                    or security systems, to create a more connected living
                                    experience. Imagine a scenario where your robot vacuum starts
                                    cleaning the moment your smart lights detect that you're out of
                                    the room or when your security system is disarmed after you come
                                    home from work.
                                </p>
                                <p className="lead">
                                    This level of integration and automation truly elevates the
                                    convenience factor, and thanks to voice assistant compatibility
                                    (with Google Assistant, Amazon Alexa, and more), controlling
                                    your robot vacuum becomes as easy as issuing a command.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Articles articles={robotVacuums} />

                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className={"mainheading"}>
                                <h2>Smart Garage Solutions</h2>
                                <h3>Convenience at Your Fingertips</h3>
                                <p className="lead">
                                    Our selection of smart garage products offers convenience and
                                    security. With a smart garage door opener, you can control your
                                    garage remotely using your smartphone or voice commands. Never
                                    again will you have to worry about whether you closed the garage
                                    after leaving home. These systems offer peace of mind by
                                    providing real-time notifications on the status of your garage
                                    door, and some even include cameras for an added layer of
                                    security.
                                </p>
                                <p className="lead">
                                    The ability to remotely open or close the garage door also means
                                    that you can allow delivery personnel or family members access
                                    without having to be present, making it a flexible and highly
                                    practical addition to your smart home ecosystem.
                                </p>

                                <h3>Integration with Your Smart Home Ecosystem</h3>
                                <p className="lead">
                                    Many smart garage systems can be integrated with your existing
                                    smart home setup, allowing you to automate actions like closing
                                    the garage at night or opening it upon your arrival. Imagine
                                    pulling into your driveway, and as soon as your smart home
                                    detects your car's presence, the garage opens, lights turn on,
                                    and your home is ready to welcome you.
                                </p>
                            </div>
                        </Col>

                        <Col xs={12}>
                            <div className={"mainheading"}>
                                <h2>Smart Plugs for Seamless Automation</h2>
                                <h3>Versatility and Cost-Effectiveness</h3>
                                <p className="lead">
                                    Smart plugs are highly versatile and affordable. They allow you
                                    to control any appliance connected to a standard outlet, turning
                                    even basic devices into part of your smart home ecosystem.
                                    Whether it's turning on the coffee maker in the morning or
                                    switching off lights from your phone while lying in bed, smart
                                    plugs provide immense convenience.
                                </p>
                                <p className="lead">
                                    These devices are perfect for those just starting their journey
                                    into smart home automation. With minimal setup required, you can
                                    start automating your home in no time, and with more advanced
                                    models, you can track energy usage, create schedules, and even
                                    integrate with your voice assistant for hands-free control.
                                </p>

                                <h3>Energy Monitoring and Scheduling</h3>
                                <p className="lead">
                                    Many smart plugs come with features like energy monitoring,
                                    scheduling, and integration with Google Home and Amazon Alexa.
                                    This enables you to create automation routines for optimal
                                    convenience. You can easily set a schedule for when devices
                                    should be turned off to reduce energy consumption, leading to
                                    significant savings on your energy bill over time.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Articles articles={smartPlugs} />

                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className={"mainheading"}>
                                <h2>Integrating Smart Home Devices for a Connected Lifestyle</h2>
                                <h3>Building a Cohesive System</h3>
                                <p className="lead">
                                    A modern smart home thrives on the integration of various
                                    devices. By connecting smart plugs, robot vacuums, and garage
                                    systems, you can create a fully automated and efficient living
                                    space. The seamless interplay between these devices ensures that
                                    your home is not only more convenient but also more
                                    energy-efficient and secure.
                                </p>
                                <p className="lead">
                                    A connected lifestyle allows you to set complex automation
                                    routines where one action triggers multiple devices. For
                                    instance, when your smart home detects that you're leaving, it
                                    can shut down all unnecessary electronics, lock the doors, and
                                    arm the security system. Such automation makes your daily
                                    routines more streamlined and hands-off.
                                </p>

                                <h3>Compatibility and Flexibility</h3>
                                <p className="lead">
                                    Our range of smart home products ensures compatibility with
                                    popular automation platforms, allowing you to customize your
                                    smart home experience according to your needs. Whether you
                                    prefer Google Assistant, Amazon Alexa, or Apple HomeKit, we
                                    ensure that you have the flexibility to build your smart home
                                    around your platform of choice.
                                </p>
                            </div>
                        </Col>

                        <Col xs={12}>
                            <div className={"mainheading"}>
                                <h2>Why Choose Real Smart Home for Your Smart Home Products?</h2>
                                <p className="lead">
                                    At Real Smart Home, we provide top-rated smart home devices that
                                    offer reliability and ease of use. Our carefully selected
                                    products, from smart plugs to robot vacuums, help make home
                                    automation accessible for everyone.
                                </p>

                                <p className="lead">
                                    Explore our product listings and find the right smart home
                                    solutions for your needs today. Whether you're enhancing
                                    security, improving energy efficiency, or seeking more
                                    convenience, we have the products for you.
                                </p>

                                <p className="lead">
                                    Thank you for choosing Real Smart Home. We look forward to
                                    helping you build the smart home of your dreams.
                                </p>
                            </div>
                        </Col>

                        <Col xs={12}>
                            <h2>Categories</h2>
                            <ul className={"categories"}>
                                {data.allTags.nodes.map((tag: any) => (
                                    <li key={tag.snug}>
                                        <a href={"/tag" + tag.fields.slug}>
                                            <Badge bg="primary">{tag.frontmatter.name}</Badge>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                    <footer>
                        <Bio />
                    </footer>
                </Container>
            </div>
        </Layout>
    );
};

export const query = graphql`
    query Products {
        allMarkdownRemark(
            filter: { frontmatter: { type: { eq: "product" } } }
            sort: { frontmatter: { date: DESC } }
        ) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    type
                    date(formatString: "MMMM DD, YYYY")
                    title
                    lead
                    category
                    featuredImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
        allTags: allMarkdownRemark(
            filter: { frontmatter: { type: { eq: "tag" } } }
            sort: { fields: { slug: ASC } }
        ) {
            nodes {
                fields {
                    slug
                }
                frontmatter {
                    name
                }
            }
        }
    }
`;

export const Head = () => (
    <Seo
        url={"/smart-home-products/"}
        title="Comprehensive Smart Home Products Database"
        shortTitle
        description={
            "Discover top-rated smart home devices with expert reviews, comparisons, and guides to help you choose the best tech for a smarter, more connected home."
        }
    />
);

export default BlogPage;
